import { HeadFC } from "gatsby";
import React, { useEffect } from "react";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";

const PrivateJetExperience: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/1azS0DM8MTEEBErCftzna2/695f0d47874aa48261a2f5d27f996e44/private-jet-window.jpg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Private jet</h1>
                  <p className="secondary-text">Bespoke luxury experiences</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">See availability</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Experience details</h2>

               <div className="event-details-container">
                  <div className="event-details-item">
                     <span>🗓</span>
                     <p>Enquire for availability</p>
                  </div>

                  <div className="event-details-item">
                     <span>🌍</span>
                     <p>Worldwide</p>
                  </div>

                  <div className="event-details-item">
                     <span>✈️</span>
                     <p>20+ models</p>
                  </div>

                  <div className="event-details-item">
                     <span>💵</span>
                     <p>Pricing options</p>
                  </div>
               </div>

               <div className="event-summary-wrapper">
                  <p><strong>Indulge in the height of luxury and comfort with a private jet hire that can take you wherever, whenever you desire. With your own private jet, you're in total control: choose your destination, schedule and comfort level.</strong></p>

                  <p>We work with a international network of jet companies that offer a wide and impressive level of diverse, cost-effective offerings for all types of private and business clients. Private jets acquired through Prestige VIP meet the most refined standards of comfort and flexibility. Choose from a variety of aircraft to find the perfect match for your needs.</p>

                  <p>Whether it's for a business trip, family holiday or a special event, private jets offer unmatched convenience and freedom. Our luxury jet range comes equipped with the highest level amenities, including plush seating, cutting-edge entertainment systems and exquisite dining options.</p>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventName="Private jet availability"
            eventImage="https://images.ctfassets.net/welt89o8b674/45WWU90hBuMdmfwCkW40WO/d6035b8426ebe64ce9b5442127bc4261/private-jet-2.jpg"
         />

         <SomethingElseCta/>

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title={`Private Jet | Prestige VIP`}
      metaDescription={`Experience travel in the most luxurious way with your own private jet. International availabilty with a variety of options.`}
      slug="/experiences/private-jet"
   />
)

export default PrivateJetExperience